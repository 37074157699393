import React from "react";
import Helmet from "react-helmet";
import { login } from "../../utils/auth";

import Spinner from "../../components/Spinner";

export default class Account extends React.Component {
  render() {
    login("signUp");

    return (
      <React.Fragment>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Spinner />;
      </React.Fragment>
    );
  }
}
